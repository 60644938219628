import React from 'react';

export default function Contact() {
    const handleEmailCopy = () => {
        const email = "sari.ellila@gmail.com";
        navigator.clipboard.writeText(email)
            .then(() => {
                alert("Email copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy email: ", err);
            });
    };

    return (
        <div className="contact-container" id="contact-container">
            <h1>CONTACT</h1>
            <div className="contact-divider"></div>
            <div className="contact-info">
                <h4>
                    <span 
                        className="email" 
                        onClick={handleEmailCopy} 
                        style={{ cursor: 'pointer', textDecoration: 'none' }}
                        title="Click to copy"
                    >
                        sari.ellila@gmail.com
                    </span>
                </h4>
                <h4>
                    <a 
                        href="https://www.linkedin.com/in/sari-susanna-ellilä-29a0a9189/" 
                        target="_blank" 
                        rel="noreferrer"
                    >
                        LinkedIn
                    </a>
                </h4>
            </div>
        </div>
    );
}
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import becary from '../assets/becary.png';
import irud from '../assets/irud.png';
import jpntravel from '../assets/jpntravel.png';
import giftforlife from '../assets/giftforlife.png';
import contrast from '../assets/contrast.png';
import HorrorDBScreenGrab from '../assets/HorrorDBScreenGrab.mp4';
import { CiPlay1 } from "react-icons/ci";
import HorrorDBtn from '../assets/HorrorDBtn.png';

export default function Work() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const projects = [
    {
      title: "Becary",
      subtitle: "DEVELOPMENT & COLLABORATED DESIGN",
      skills: "SKILLS USED: Figma, HTML, CSS, Javascript, React, Firebase",
      description:
        "Working closely with start-up Becary to create an attractive website and a structure easy to navigate. Booking are registered behind admin - login using Google Firebase at the moment.",
      image: becary,
      website: "http://satisfying-cushion.surge.sh",
      code: "https://gitfront.io/r/sarisusanna/muipD68iyuKq/becary-app/",
    },
    {
      title: "Iru-D-Studio",
      subtitle: "DEVELOPMENT & COLLABORATED DESIGN",
      skills: "SKILLS USED: Figma, HTML, CSS, Javascript, React",
      description:
        "Working with Iru-D-Studio creating a more stylish and dynamic page for them. Special points in the project are the CSS and animation aspects.",
      image: irud,
      website: "http://utopian-babies.surge.sh",
      code: "http://utopian-babies.surge.sh",
    },
    {
      title: "Contrast Club",
      subtitle: "DEVELOPMENT",
      skills: "SKILLS USED: HTML, CSS, Javascript, React",
      description:
        "Creating a readily set design to showcase a new collection of the brand. A simple project, but I learned about working to deliver an accurate design determined by the customer",
      image: contrast,
      website: "http://wandering-nail.surge.sh",
      code: "https://github.com/SariEllila/ContrastClub-React",
    },
    {
      title: "Gift for Life",
      subtitle: "DESIGN & DEVELOPMENT",
      skills: "SKILLS USED: Figma, HTML, CSS, Javascript, React, Node.js",
      description:
        "An online store with product information being rendered from a MongoDB Atlas database in the backend. A payment method is being implemented with Stripe.",
      image: giftforlife,
      website: "https://giftforearth.onrender.com/",
      code: "https://gitfront.io/r/sarisusanna/Hop4Rb67inkL/FirstWebApp/",
    },
    {
      title: "Travel Japan",
      subtitle: "DESIGN & DEVELOPMENT",
      skills: "SKILLS USED: HTML, CSS, Javascript, React",
      description:
        "Japan travel destinations information website. It includes a weather API and a quiz to determine the most suitable destination for you.",
      image: jpntravel,
      website: "http://superficial-sense.surge.sh",
      code: "https://github.com/SariEllila/TRAVEL-APP",
    },
    {
      title: "Horror Database",
      subtitle: "DESIGN & DEVELOPMENT",
      skills: "SKILLS USED: HTML, CSS, Javascript, React Native, Expo",
      description:
        "A movie database mobile app specified for the horror genre. It uses TMDB Movie API to receive the film information.",
      image: HorrorDBtn,
      video: HorrorDBScreenGrab,
      code: "https://gitfront.io/r/sarisusanna/Rcghryp6jSeR/MovieDB/",
    },
  ];

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="homepage-work-container">
      <Link to="./home">
        <button>HOME</button>
      </Link>
      <div className="work-grid">
        {projects.map((project, index) => (
          <div key={index} className="work-item">
           <div>
  {project.image && (
    <div
      className={`video-container ${
        project.title === "Horror Database" ? "small-image" : ""
      }`}
    >
      <img
        src={project.image}
        alt={project.title}
        className="project-image"
      />
      {project.video && (
        <CiPlay1 className="play-icon" onClick={openModal} />
      )}
    </div>
  )}
</div>
            <div className="work-details">
              <h4>{project.subtitle}</h4>
              <h2>{project.title}</h2>
              <p>{project.skills}</p>
              <div className="work-buttons">
                {project.website && (
                  <a href={project.website} target="_blank" rel="noopener noreferrer">
                    <button>VIEW WEBSITE</button>
                  </a>
                )}
                <a href={project.code} target="_blank" rel="noopener noreferrer">
                  <button>VIEW CODE</button>
                </a>
              </div>
              <h3>{project.description}</h3>
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-modal" onClick={closeModal}>
              Close
            </button>
            <video controls className="modal-video">
              <source src={HorrorDBScreenGrab} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </div>
  );
}
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default function Header() {
  const location = useLocation(); // Get current location (route)

  return (
    <div className="header">
      <div></div>
      <ul className="nav">
        <li>
          {location.pathname === '/work' ? (
            // If we're on the Work page, show "HOME" instead of "WORK"
            <Link to="/home">
              <li>HOME</li>
            </Link>
          ) : (
            // Otherwise, show "WORK"
            <Link to="./work">
              <li>WORK</li>
            </Link>
          )}
        </li>

        <li>
          {location.pathname === '/about' ? (
            // If we're on the About page, show "HOME" instead of "ABOUT ME"
            <Link to="/home">
              <li>HOME</li>
            </Link>
          ) : (
            // Otherwise, show "ABOUT ME"
            <Link to="./about">
              <li>ABOUT ME</li>
            </Link>
          )}
        </li>

        <li>
          <HashLink smooth to="/#contact-container">
            CONTACT
          </HashLink>
        </li>
      </ul>
    </div>
  );
}
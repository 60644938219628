import React, { useState } from 'react';
import omakuva from '../assets/omakuvauusi.jpg';
import { CiCircleChevDown, CiCircleChevUp } from "react-icons/ci";

export default function About() {
  const [view, setView] = useState('study');

  const handleViewChange = (newView) => {
    setView(newView);
  };

  return (
    <div className="home-body-container-two">
      <div className="homepage-title">
        <h3>WEB DEVELOPMENT / DESIGN</h3>
        <h1>ABOUT <br /> ME</h1>

        <div className="aboutme-content">
        {view === 'study' && (
  <div className="study-bg-list">
    <ul>
      <li><strong>Javascript Fullstack Bootcamp</strong> / Barcelona Code School <br /> (Oct 2023 - Dec 2023)</li>
      <li><strong>Graphic Design Degree</strong> / Helsinki Design School <br /> (Feb 2022 - Dec 2022)</li>
      <li><strong>Web Design & Development Freelancer</strong> <br /> (Jan 2024 - present)</li>
    </ul>
    <p className="icon-wrapper" onClick={() => handleViewChange('skills')}>
      <CiCircleChevDown className="down-arrow" />
    </p>
  </div>
)}

{view === 'skills' && (
  <div className="aboutme-skills">
    <h2>Skills:</h2>
    <ul>
    <li>
    <h3><span>Development: <br /></span>HTML / CSS / JS + Typescript (WIP) <br /> React / React Native / Node.js</h3>
    </li>
    <li>
    <h3><span>Design:<br /></span> Figma / Adobe Suite</h3>
    </li>
    </ul>
    <p className="icon-wrapper" onClick={() => handleViewChange('intro')}>
      <CiCircleChevDown className="down-arrow" />
    </p>
  </div>
)}

{view === 'intro' && (
  <div className="aboutme-intro">
    <h2>
      My assets are <em>learning fast</em> and being an excellent and reliable <em>team worker</em>. In addition from my various and international work background I have acquired both great communicative and problem-solving skills. I am currently working on a TypeScript course to add to my skillset. 
    </h2>
    <p className="icon-wrapper" onClick={() => handleViewChange('study')}>
      <CiCircleChevUp className="up-arrow" />
    </p>
  </div>
)}
        </div>
      </div>

      <div>
        <img src={omakuva} alt="omakuva" className="omakuva" />
      </div>
    </div>
  );
}